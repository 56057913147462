import React, { useEffect, useState }
from 'react';
import axios from 'axios';
import JobCard from './JobCard';
import { id }
from '.././constants';
import JobView from './JobView';
import dataJob from "../data.json";

const URL = require("../constants");
const JobList = (props) => {

    const [data, setdata] = useState([])
    useEffect(() => {
    const GetData = async () => {
    await axios({
    method: 'get',
        //  url: `http://192.168.1.3/biz-skpl-job/api/v1/job?company_id=${id}`,
          url: `https://job.skcplindia.in/api/v1/job?company_id=${id}`,
    }).then((res) => {
               // console.log(res.data)
                        setdata(res.data.data)
                })
                }
                GetData()
                }, [])


                        return (
                                <>
<section className="p-0 mt-5">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
                <img
                    className="img-fluid w-100"
                    src=                    
                    
                        {`${URL.img_url}${dataJob.data.career[0].about.img_url}`}
                    alt={dataJob.data.career[0].about.title}
                    />
            </div>
            <div className="col-lg-6 col-md-12 md-mt-5">
                <div className="section-title mb-3">
                    <div className="title-effect title-effect-2">
                        <div className="ellipse"></div>{" "}
                        <i className="la la-info"></i>
                    </div>
                    <h2>{dataJob.data.career[0].about.title}</h2>
                </div>
                <p className="lead">
                    {dataJob.data.career[0].about.description}
                </p>
            </div>
        </div>
    </div>
</section>

                        {data.length !== 0 &&
                                <section className="p-0 mt-5 text-center">
    <div className="container">
        <div className="row">
            <div className="col-lg-8 col-md-12 ml-auto mr-auto">
                <div className="section-title">
                    <div className="title-effect title-effect-2">
                        <div className="ellipse"></div>
                        {" "}
                        <i className="la la-cubes"></i>
                    </div>
                    <h2 className="title">
                        {
                                dataJob.data.career[0].hiring.title
                        }

                    </h2>
                    <p>                        
                        {
                                dataJob.data.career[0].hiring.description
                        }

                    </p>
                </div>
            </div>
        </div>
        {data.map((data,index) => ( <JobCard data={data} key={index} /> ))}
    </div>
</section>
}
                        
<section className="p-0 mt-5 pb-3 text-center">
    <div className="container">
        <div className="row">
            <div className="col-lg-8 col-md-12 ml-auto mr-auto">
                <div className="section-title">
                    <div className="title-effect title-effect-2">
                        <div className="ellipse"></div>
                        
                        {" "
}
                        
                        <i className="la la-cubes"></i>
                    </div>
                    <h2 className="title">
                        
                        {
                                dataJob.data.career[0].jobcv.title
                        }
                        
                    </h2>
                    <p>                        
                        
                        {
                                dataJob.data.career[0].jobcv.description
                        }
                        
                    </p>
                </div>
            </div>
        </div>
        <div className="text-left">
            <JobView 
                        {...props}
                        hide={false}
                        />
        </div>
    </div>
</section>
</>
)
}

export default JobList
