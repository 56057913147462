import React, { useState, useEffect } from "react";
//import { useLocation } from 'react-router-dom';
import Loader from "./Loader";
import dataHeader from "./data.json";
//import './theme-color/color-1.css'
const URL = require("./constants");
const Header1 = () => {
    const [breadcrumb, setBreadcrumb] = useState('');
    useEffect(function () {
        // console.log(window.localStorate);
        const storedPage = localStorage.getItem("page");
        setBreadcrumb(storedPage);
      //  console.log(storedPage);
    });
    const fun = (value) => {
        console.log(value);
        localStorage.setItem("page", value);
    };
    return (
        <>
            <Loader />
            <header id="site-header" className="header header-1">
                <div className="container-fluid">
                    <div id="header-wrap" className="box-shadow">
                        <div className="row">
                            <div className="col-lg-12">


                                {/* Navbar */}


                                <nav className="navbar navbar-expand-lg">
                                    <a className="navbar-brand logo" href="/">
                                        <img
                                            id="logo-img"
                                            className="img-center"
                                            src=

                                            {`${URL.img_url}${dataHeader.data.home[1].logo}`
                                            }


                                            alt="Bizolution Technologoes Pvt. Ltd."
                                        />
                                    </a>
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#navbarNavDropdown"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                    >


                                        {" "
                                        }


                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </button>
                                    <div
                                        className="collapse navbar-collapse"
                                        id="navbarNavDropdown"
                                    >


                                        {/* Left nav */}


                                        <ul
                                            id="main-menu"
                                            className="nav navbar-nav ml-auto"
                                        >
                                            <li className="nav-item">


                                                {" "
                                                }


                                                <a
                                                    className=

                                                    {`nav-link 
                                ${breadcrumb === "home"
                                                            ? "active"
                                                            : ""
                                                        }`}


                                                    onClick={() => fun('home')}


                                                    href="/" title="Home"
                                                >
                                                    Home
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a

                                                    className=

                                                    {`nav-link 
                                ${breadcrumb === "about"
                                                            ? "active"
                                                            : ""
                                                        }`}


                                                    onClick={() => fun('about')}


                                                    href="/about-us" title="About Us"
                                                >
                                                    About Us
                                                </a>
                                            </li>
                                            <li className="nav-item">


                                                {" "
                                                }


                                                <a
                                                    className=

                                                    {`nav-link 
                                ${breadcrumb === "service"
                                                            ? "active"
                                                            : ""
                                                        }`}


                                                    onClick={() => fun('service')}


                                                    href="/product" title="Services"
                                                >
                                                    Product
                                                </a>
                                            </li><li className="nav-item">
                                                <a

                                                    className=

                                                    {`nav-link 
                                ${breadcrumb === "career"
                                                            ? "active"
                                                            : ""
                                                        }`}


                                                    onClick={() => fun('career')}


                                                    href="/career" title="Career"
                                                >
                                                    Career
                                                </a>
                                            </li>
                                            <li className="nav-item">


                                                {" "
                                                }


                                                <a
                                                    className=

                                                    {`nav-link 
                                ${breadcrumb === "contact"
                                                            ? "active"
                                                            : ""
                                                        }`}


                                                    onClick={() => fun('contact')}


                                                    href="/contact-us" title="Contact Us"
                                                >
                                                    Contact Us
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header1;
