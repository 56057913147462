import React from "react";
import dataSteps from "../../data.json";
const URL = require("../../constants");

const Steps = () => {
	return (
		<>
			<section className="text-center pos-r light-blue">
				<div className="container custom-svgstep">
					<div className="row">
						<div className="col-lg-12 col-md-12 ml-auto mr-auto">
							<div className="section-title">
							<div className="title-effect title-effect-2">
									<div className="ellipse"></div>{" "}
									<i className="la la-info"></i>
								</div>
								<h2 className="title">
								{dataSteps.data.home[1].content.steps.title}
								</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div id="svg-container">
							<svg
								id="svgC"
								width="100%"
								height="100%"
								viewBox="0 0 620 120"
								preserveAspectRatio="xMidYMid meet"
							></svg>
						</div>
						{dataSteps.data.home[1].content.steps.step.map(
							(step, index) => (
								<div className="col-lg-4 col-md-12" key={index}>
									<div className="work-process">
										<div className="box-loader">
											{" "}
											<span></span>
											<span></span>
											<span></span>
										</div>
										<div className="step-num-box">
											<div className="step-icon">
												<span>
													<i
														className={`${step["icon"]}`}
													></i>
												</span>
											</div>
											<div className="step-num">
												{step["count"]}
											</div>
										</div>
										<div className="step-desc">
											<h4>{step["title"]}</h4>
											<p className="mb-0">
												{step["description"]}
											</p>
										</div>
									</div>
								</div>
							)
						)}
					</div>
				</div>
			</section>
		</>
	);
};
export default Steps;
