import React from "react";
import dataIndustry from "../../../data.json";
const URL = require("../../../constants");

const Industry = () => {
	return (
		<section className="pos-r text-center wow fadeInUp"
			data-wow-duration="2s">
			<div className="container-fluid">
				<div className="row align-items-center">
					<div className="col-xl-12 col-lg-12 col-md-12">
						<div className="section-title mb-3">
							<div className="title-effect title-effect-2">
								<div className="ellipse"></div>{" "}
								<i className="la la-users"></i>
							</div>
							<h2 className="title">
								{dataIndustry.data.home[1].content.industry.title}
							</h2>
						</div>
					</div>
				</div>
				<div className="row  md-mt-0">
					<div className="col-md-12">
						<div
							className="owl-carousel owl-theme custom-owl-domain"
							data-dots="false"
							data-items="5"
							data-lg-items="5"
							data-md-items="4"
							data-sm-items="1"
							data-autoplay="true"
						>
							{dataIndustry.data.home[1].content.industry.details.map(
								(industry, index) => (
									<div className="item" key={index}>
										<div className="featured-item style-3 custom-featured">
											<div className="featured-icon">
												{" "}
												<img
													className="img-center"
													src={`${URL.img_url}${industry["icon"]}`}
													witdh="100" alt={industry["title"]}
												/>
											</div>
											<div className="featured-title pt-3">
												<h6>{industry["title"]}</h6>
											</div>
										</div>
									</div>
								)
							)}
						</div>
					</div>
				</div>
				<div className="row align-items-center">
					<div className="col-xl-12 col-lg-12 col-md-12">
						<h4 className="title mb-3">
							{dataIndustry.data.home[1].content.industry.sub_title}
						</h4>
						<h4 className="title mb-3">
							{dataIndustry.data.home[1].content.industry.sub_title_two}
						</h4>
						<a className="btn btn-theme btn-circle" href="/contact-us" title={dataIndustry.data.home[1].content.industry.sub_title_three}>
							{dataIndustry.data.home[1].content.industry.sub_title_three}
						</a>
					</div>
				</div>
			</div>
		</section>
	);
};
export default Industry;
