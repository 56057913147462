import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeTwo from "./home/homeTwo";
import Error404 from "./pages/error404";
import ComingSoon from "./pages/comingSoon";
import AboutTwo from "./about/aboutTwo";
import productOne from "./products/productOne"
// import refund from "./refund";
import ServiceOne from "./services/serviceOne";
import ServiceTwo from "./services/serviceTwo";
import ContactOne from "./contact/contactOne";
import ProductOne from "./products/productOne/index";
import Login from "./login";
import SignUp from "./signup";
import Privacy from "./policy/privacy";
import Refund from "./refund";
import Product from "./products/productOne/Product";
import Terms from "./policy/terms";
import LoginSuccess from "./pages/loginSuccess";
import SignupSuccess from "./pages/signupSuccess";
import Carrier from './pages/careers'
import JobForm from './pages/careers/JobForm'
import Faq from './pages/faq'
import Blogone from './pages/blogs/blogone'

function MainNavigation() {
  return (
    <Router>
      <Switch>
        {/** Home */}
        <Route path="/" exact={true} component={HomeTwo} />
        <Route path="/home-two" exact={true} component={HomeTwo} />
        {/** Pages */}
        <Route path="/comingsoon" exact={true} component={ComingSoon} />
        <Route path="/loginsuccess" exact={true} component={LoginSuccess} />
        <Route path="/signupsuccess" exact={true} component={SignupSuccess} />
        <Route path="/error404" exact={true} component={Error404} />
        <Route path="/career-apply/:id" exact={true} component={JobForm}/>
        {/** About Services & Contact */}
        <Route path="/about-us" exact={true} component={AboutTwo} />
        <Route path="/product" exact={true} component={productOne} />
        <Route path="/service" exact={true} component={ServiceOne} />
        <Route path="/service-two" exact={true} component={ServiceTwo} />
        <Route path="/contact-us" exact={true} component={ContactOne} />
        <Route path="/construction" exact={true} component={ProductOne} />
        <Route path="/career" exact={true} component={Carrier}/>
        {/** Login & Policy */}
        <Route path="/login" exact={true} component={Login} />
        <Route path="/register" exact={true} component={SignUp} />
        <Route path="/privacy" exact={true} component={Privacy} />
        <Route path="/refund" exact={true} component={Refund} />
        <Route path="/terms" exact={true} component={Terms} />
        <Route path="/faq" exact={true} component={Faq} />
        <Route path="/blog/5-tech-mistakes-made-by-start-ups" exact={true} component={Blogone} />
   
  
        {/** invalid routes redirection */}
        <Route component={Error404} />
      </Switch>
    </Router>
  );
}

export default MainNavigation;
