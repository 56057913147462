import React, {useState}
from 'react'



        const JobDetails = (props) => {
const {data} = props
        const [state, setState] = useState('')
        let Data = JSON.stringify(data?.job_description)

        function createMarkup() {
        return {
        __html: data?.job_description}
        ;
        }
return (
        <div className="subscribe-inner p-2">
    <h4 className="border-bottom py-3 mb-4 text-primary"><b>{data?.job_title?.slice(0, 80)}
    {data.job_title?.length >= 80?'...':''}</b>
    </h4>
    <div className="py-3 px-4">{data?.job_description && <div dangerouslySetInnerHTML={createMarkup()}></div>}</div>
    <div className="row py-3 px-4">      
        {data?.education && <div className="col-md-6 mb-3"><strong>Education:</strong> {data?.education}</div>}
        {data?.experience && <div className="col-md-6 mb-3"><strong>Experience:</strong> {data?.experience}yrs</div>}
        {data?.location && <div className="col-md-6 mb-3"><strong>Location:</strong> {data?.location}</div>}
        {data?.salary && <div className="col-md-6 mb-3"><strong>Salary:</strong> < span className = "fas fa-rupee-sign" > </span> {data?.salary} / - pm</div>}
    </div>
</div>
        )
        }

export default JobDetails
