import React from "react";
import dataStartup from "../../../data.json";
const URL = require("../../../constants");

const Startup = () => {
	return (
		<>
			<div>
				<section className="bg-effect pos-r">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-5 col-md-12 image-column">
							<img
									className="img-fluid"
									src="images/startup/automation-block.png"
									alt={dataStartup.data.home[1].content.startup[0].title}
								/>
							</div>
							<div className="col-lg-6 col-md-12 md-mt-5 ml-auto">
								<div className="section-title mb-3">
									<div className="title-effect title-effect-2">
										<div className="ellipse"></div>{" "}
										<i className="la la-info"></i>
									</div>
									<h4>
										{dataStartup.data.home[1].content.startup[0].title}
									</h4>
								</div>
								<p className="lead"><b><i>
									{
										dataStartup.data.home[1].content.startup[0].description
									}
								</i></b></p>
								<ul className="custom-li list-unstyled list-icon-2 d-inline-block">
									{dataStartup.data.home[1].content.startup[0].details.map(
										(detail, index) => (
											<li key={index}><p>{detail.title}</p></li>
										)
									)}
								</ul>
							</div>
						</div>
					</div>
				</section>
			
				<section>
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-6 col-md-12 order-lg-12">
							<img
								className="img-fluid w-100"
								src="images/startup/progress-block.png"
								alt={dataStartup.data.home[1].content
									.startup[1].title}
							/>
						</div>
						<div className="col-lg-6 col-md-12 md-mt-5 order-lg-1">
							<div className="section-title mb-3">
								<div className="title-effect title-effect-2">
									<div className="ellipse"></div>{" "}
									<i className="la la-info"></i>
								</div>
								<h4>
									{dataStartup.data.home[1].content
											.startup[1].title}
								</h4>
							</div>
							<p className="lead">
								{
									dataStartup.data.home[1].content
									.startup[1].description
								}
							</p>
							<ul className="custom-li list-unstyled list-icon-2 d-inline-block">
									{dataStartup.data.home[1].content.startup[1].details.map(
										(detail, index) => (
											<li key={index}><p>{detail.title}</p></li>
										)
									)}
								</ul>
						</div>
					</div>
				</div>
			</section>
			<section className="pos-r o-hidden text-center">
				<div className="container">
					<div className="row">
						<div className="col-lg-8 col-md-12 ml-auto mr-auto">
							<div className="section-title">
								<div className="title-effect title-effect-2">
									<div className="ellipse"></div>{" "}
									<i className="la la-cubes"></i>
								</div>
								<h3 className="title">
									{
										dataStartup.data.home[1].content.startup[2].title
									}
								</h3>
								<p>
									{
										dataStartup.data.home[1].content.startup[2].description
									}
								</p>
							</div>
						</div>
					</div>
				</div>
			</section>				
			</div>
		</>
	);
};
export default Startup;
