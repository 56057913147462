import React from "react";
import Header from "../../Header1";
import Footer from "../../Footer1";
import PageContent from "./content";
// import dataHead from "../../data.json";
// import { Helmet } from "react-helmet";

const HomeTwo = () => {
	return (
		<>
			<div className="page-wrapper">
				<Header />
				<PageContent />
				<Footer />
			</div>
		</>
	);
};
export default HomeTwo;
