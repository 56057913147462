import React from "react";

const Title = () => {
	return (
		<section
			className="page-title o-hidden pos-r md-text-center"
			data-bg-color="#00c9ff"
		>
			<canvas id="confetti"></canvas>
			<div className="container">
				<div className="row align-items-center">
					<div className="col-lg-7 col-md-12">
						<h1 className="title">
							<span>S</span>ervices
						</h1>
						<p>We're Building Modern And High Software</p>
					</div>
					<div className="col-lg-5 col-md-12 text-lg-right md-mt-3">
						<nav
							aria-label="breadcrumb"
							className="page-breadcrumb"
						>
							<ol className="breadcrumb">
								<li className="breadcrumb-item">
									<a href="/">Home</a>
								</li>
								{/* <li className="breadcrumb-item">
									<a href="/#">Services</a>
								</li> */}
								<li
									className="breadcrumb-item active"
									aria-current="page"
								>
									Services
								</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Title;
