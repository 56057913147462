import React from "react";
import Header from "../Header1";
import Footer from "../Footer1";
import Title from "./Title";
import Refund from "./Refund";

const RefundPolicy = () => {
	return (
		<>
			 <Header />
			<Title />
            <Refund/>
			 
			<Footer /> 
           
		</>
	);
};
export default RefundPolicy;

