import React from "react";
import Header from "../../Header1";
import Footer from "../../Footer1";
import Title from "./Title";
import About from "./About";
import Services from "./Services";
import Counter from "./Counter";
import Faq from "./Faq";
import Steps from "./Steps";
import Features from "./Features";
import Pricing from "./Pricing";
import Testimonial from "./Testimonial";
import Blog from "./Blog";

const AboutTwo = () => {
	return (
		<>
			<Header />
			<Title />
			<div className="page-content">
				<About />
				<Features />
				<Steps />				
				<Faq />
				{/* <Pricing /><Counter />
				<Testimonial /> 
				<Blog />*/}
			</div>
			<Footer />
		</>
	);
};
export default AboutTwo;
