import React from 'react';
import Header from '../../Header1';
import Title from '../../careers/Title';
import JobList from '../../careers/JobList';
import Footer from "../../Footer1";
const Carrier = (props) => {
return (
        <>
            <Header />
            <Title />
            <div className="page-content">
            <JobList {...props}/>
            </div>
            <Footer />
         
        </>
    )
}

export default Carrier
