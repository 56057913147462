import React from 'react'
import {JOB_TITLE} from '../constants'
        const JobCard = (props) => {
    const {data} = props
            return (
                    <>
<div className="job-listbox text-left">
    <div className="job-list">
        <div className="row">
            <div className="col-lg-10">
                <div className="content">
                    <h5><a href=

                           
            {`/career-apply/${data.id}`}> {data.job_title.slice(0, JOB_TITLE)}{data.job_title.length >= JOB_TITLE?'...':''} </a></h5>
                    <div className="info">
                        <ul>
                            {data.location && <li><span className="company job-list-font"><i className="fas fa-map-marker-alt mr-1"></i>{data.location}</span></li> }
                            {data.salary && <li><span className="office-location job-list-font"><i className="fas fa-rupee-sign mr-1" ></i>{data.salary}/- pm</span></li> }
                            {data.experience && <li><span className="job-type temporary job-list-font"><i className="fas fa-briefcase mr-1"></i>min.{' '} {data.experience}yrs </span></li>}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg-2 mt-1">
                <div className="more text-center ">
                <a  href={`/career-apply/${data.id}`} className="btn btn-theme sm job-list-font" >Apply <i className="fas fa-arrow-circle-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>
</>


);
}

export default JobCard