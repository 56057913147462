import React from "react";
import termsData from "../../data.json";

const Terms = () => {
	return (
		<div className="page-content">
			<section>
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12">
							<h2 className="title mb-0">
								{termsData.data.policy.terms.title}
							</h2>
						</div>
					</div>
					<div className="row mt-5">
						<div className="col-lg-12 col-md-12">
							<p className="mb-3">These Terms and Conditions govern your use of “bizolutiontech.com” and the services provided by it. https://bizolutiontech.com provides its services subject to the following terms, conditions, and provisions. By accessing, viewing, or using its services, including but not limited to its various websites, SMS, APIs, email notifications, applications, buttons, widgets, and ads etc. you indicate that you understand these Terms and Conditions and accept them as the legal equivalent of a signed, written contract, binding you to these Terms and Conditions. </p>
							<p className="mb-3">Your participation as a customer/consumer/viewer in this service will constitute acceptance of this agreement.</p>
							<p className="mb-3">https://bizolutiontech.com is a news and current affairs website. It collects information and data from varied sources based in India and outside India. In these terms of service, we will refer to bizolutiontech.com as “we” or “us”.</p>

							<h4 className="text-dark mt-5">FORMATION OF THE CONTRACT</h4>
							<p className="mb-3">A legally binding and valid contract shall come into force between you and us as soon as you enter the website www.bizolutiontech.com.</p>

							<h4 className="text-dark mt-5">WARRANTIES</h4>
							<p className="mb-3">
								<ol>
									<li>You warrant that you are legally capable of entering into binding contracts.</li>
									<li>You further warrant that all the information provided to us during the registration process, if any, is true and accurate. If you provide us with an e-mail address, that will result in e-mails or SMS/Mobile Notifications that we send to you via a computer or telephone network operated or owned by a third party, then, you warrant that you are entitled to receive those messages.</li>
								</ol>
							</p>

							<h4 className="text-dark mt-5">COPYRIGHT AND LIMITATIONS ON USE</h4>
							<p className="mb-3">The Content displayed or received by you through the subscription to our services or viewing our website are the property of our licensors, or ours and is protected by Copyright and other Intellectual Property Laws. Our website and the Content may be used only for your personal and non-commercial use. Reproduction of part or all of the Contents of our website in any form is expressly prohibited. Access to the information on our website shall in no manner imply any right or permission to copy, or allow for incorporation of any material, or any part therein, in any work or publication not limited to any other form. By accessing our website, you agree that reproduction, printing, re-transmission, copying, distribution, publishing or selling of any Content provided on our website is expressly prohibited. In particular, and without limitation, you expressly agree that you shall not post any Content from our website to any email lists, newsgroups or electronic bulletin boards, without our prior written consent. All rights (including Copyright) in relation to the Information/Content on our website exclusively belong to us. Any violation(s) by you or any person acting on your behalf shall subject you to legal consequences, not limited to damages, fines/penalties, and any other necessary court action.</p>

							<h4 className="text-dark mt-5">DISCLAIMER</h4>
							<p className="mb-3">
								<ol>
									<li>We have taken due care and caution in compilation of the Content for our website. The Content includes facts, views, and opinions, which are of individuals and not that of the website or its management. Our Content licensors and we do not give any investment advice, tax advice, legal advice, or other professional advice. Our Content licensors and we do not guarantee or warrant the accuracy, completeness or timeliness of, or otherwise endorse these views, and opinions. We especially state that we have no financial liability whatsoever to you on account of the use of information provided on our website.</li>
									<li>Neither we, nor any of our employees are responsible for any errors, omissions, or representations on any of our pages or on any links on any of our pages. We do not endorse in anyway any advertisers on our webpages. We advise you to verify the veracity of all information before undertaking any alliance.</li>
								</ol>
							</p>

							<h4 className="text-dark mt-5">PROPRIETARY RIGHTS</h4>
							<p className="mb-3">
								<ol>
									<li>International copyright, database right and other intellectual property rights owned by third party licensors or us protect our Content. All product and company names and logos mentioned on our website may be trademarks, service marks or trading names of their respective owners, including ours.</li>
									<li>You may display the Content to one person electronically on a single computer, download and store one copy of the information in machine readable form, print (but not photocopy) one copy of the information and store such pages for caching purposes only. Except in the case of information you have placed on our website, you may not do any of the following without our prior written consent, or the prior written consent of the owner of the intellectual property rights, as the case may be:
										<ol>
											<li>download, display or store any of the Content otherwise than as permitted above;</li>
											<li>modify, reproduce, transmit, publish, display, copy, distribute, broadcast, adapt, create derivative works of or in any way commercially exploit any of the Content;</li>
											<li>sub-license, rent, lease, transfer or attempt to assign the rights in the Content to any other person, make the materials available on a network, use the information in any manner, or transfer or export the Content or any copies into any country, other than in compliance with these Terms and with applicable laws or allow any other person to use the information other than in accordance with the Terms and the subscription agreement; </li>
											<li>redistribute any of the Content (including using it as part of any library, archive or similar service);</li>
											<li>remove the copyright or trade mark notice(s) from any copies of the Content permitted in accordance with these Terms;</li>
											<li>systematically or regularly download, store or print any of the Content so as to create a database in electronic or paper form; and</li>
											<li>deep link to, frame, spider, harvest or scrape the Content or otherwise access the Content for similar purposes.</li>
										</ol>
									</li>
								</ol>
							</p>

							<h4 className="text-dark mt-5">LINKING SITES</h4>
							<p className="mb-3">
								Our website or the Content may provide links to other Internet sites. We do not necessarily endorse these sites. We do not have any control over the content of these sites. If we receive requests to remove links from its database and search services, we reserve the right to a
								<ol>
									<li>Our website or the Content may provide links to other Internet sites. We do not necessarily endorse these sites. We do not have any control over the content of these sites. If we receive requests to remove links from its database and search services, we reserve the right to address such requests individually.</li>
									<li>Please note that in such cases, where you refer to the content or information of another website, the resulting legal contract shall be between you and that third party website owner, and shall be subject to the terms of that third party website, which they shall advise you of themselves. You shall carefully review their terms and conditions applying to the transaction. We shall not be liable for any loss or damage caused or alleged to be caused by or in connection with use of or reliance on any content, goods or services available on or through any third party website.</li>
									<li>Our site may contain certain advertising and/or sponsorships. Advertisers and/or sponsors are responsible for ensuring that material submitted for inclusion on our website complies with applicable laws, codes and regulations. We exclude, subject to the applicable law, any responsibility for any error, omission or inaccuracy appearing in any advertising or sponsorship material. Please refer to our privacy policy at http://www.bizolutiontech.com/site/privacy.html in relation to the use of cookies and the provision of aggregated information in connection with the targeting of potential advertisers.</li>
								</ol>
							</p>

							<h4 className="text-dark mt-5">INDEMNIFICATION</h4>
							<p className="mb-3">You shall indemnify, hold harmless and defend us, our subsidiaries and affiliates, licensors and all our members, directors, officers, employees, agents and representatives, from or against any liabilities, claims, demands, penalties, fines, lawsuits, judgments, losses and expenses (including any direct or indirect consequential losses, loss of profit, loss of reputation and all interest, penalties and legal and other professional costs and expenses) resulting in any way from the use of this website or any allegation of infringement or misappropriation of any patent, copyright, trade secret, trademark, or other intellectual property right under this Agreement.</p>

							<h4 className="text-dark mt-5">NOTICES</h4>
							<p className="mb-3">All notices given by you must be given to us at our official address mentioned here https://bizolutiontech.com/site/about.html or be emailed at info@bizolutiontech.com.</p>

							<h4 className="text-dark mt-5">SEVERABILITY</h4>
							<p className="mb-3">If any of these terms or any provisions of the Agreement are determined by any competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision will to that extent be severed from the remaining terms, conditions and provisions which will continue to be valid to the fullest extent permitted by law.</p>

							<h4 className="text-dark mt-5">OUR RIGHT TO VARY THESE TERMS</h4>
							<p className="mb-3">
								<ol>
									<li>We shall have the right to revise and amend these terms from time to time.</li>
									<li>You agree to keep regular track of these terms, which are always available on our website and agree that they are binding on you.</li>
								</ol>
							</p>

							<h4 className="text-dark mt-5">JOINTLY DRAFTED</h4>
							<p className="mb-3">This Agreement shall be deemed to have been jointly drafted by both the parties and, in the event of a dispute, shall not be construed against either party solely for the reason that it has or has not drafted the agreement. Each party acknowledges that it has had the opportunity to consult with a counsel of its own choosing prior to entering into this Agreement.</p>

							<h4 className="text-dark mt-5">HEADINGS AND CONSTRUCTIONS</h4>
							<p className="mb-3">The headings of the paragraphs of this Agreement are inserted for convenience only and are not intended to affect its meaning or interpretation. Throughout this Agreement, the singular shall apply to the plural and the plural to the singular, unless the context clearly indicates otherwise.</p>

							<h4 className="text-dark mt-5">LIMITATION OF LIABILITY</h4>
							<p className="mb-3">Neither we nor any other party involved in creating, producing, or delivering the website, app, services, or content will be liable for any incidental, special, exemplary or consequential damages, including lost profits, loss of data or goodwill, service interruption, computer damage or system failure or the cost of substitute goods or services arising out of or in connection with these terms or from the use of or inability to use the app, services or content, whether based on warranty, contract, tort (including negligence), product liability or any other legal theory, and whether or not we have been informed of the possibility of such damage, even if a limited remedy set forth here is found to have failed of its essential purpose.</p>

							<h4 className="text-dark mt-5">EFFORTS TO RESOLVE DISPUTES</h4>
							<p className="mb-3">The Parties shall use their best efforts to resolve any dispute under or in relation to this Agreement quickly and amicably to achieve timely and full performance of the terms of this Agreement.</p>

							<h4 className="text-dark mt-5">NOTICE OF DISPUTE AND INFORMAL RESOLUTION</h4>
							<p className="mb-3">Any party which claims that a dispute, controversy, or claim has arisen under or relating to the Agreement must give written notice thereof to the other party as soon as practicable after the occurrence of the event, matter, or thing which is the subject of such dispute. In such notice, such party shall provide particulars of the circumstances and nature of such dispute and of its claim(s) in relation thereto and shall designate a person as its representative for negotiations relating to the dispute, which person shall have the authority to bind the party in the settlement of the dispute. Within fourteen (14) calendar days from receipt of such notice, the receiving party shall promptly give reply in writing to the other party specifying its position in relation to the dispute and designating its representative in negotiation relating to the dispute. The designated representative of each party shall use all reasonable endeavors to settle the dispute within ten (10) calendar days.</p>

							<h4 className="text-dark mt-5">AGREED DISPUTE RESOLUTION PROCESS</h4>
							<p className="mb-3">
								<ol>
									<li>If the parties’ designated representatives cannot resolve the dispute within the time specified in preceding sub-section, then either of the parties shall, within a period of twenty-eight (28) days, may give notice to the other party, of its intention to commence arbitration, as hereinafter provided, as to the matter in dispute. No arbitration in respect of this matter may be commenced unless such notice is given.</li>
									<li>Any dispute or difference in respect of which a notice of intention to commence arbitration as above has been given, it shall be finally settled by arbitration.</li>
									<li>A Sole Arbitrator appointed by mutual consent of the parties shall hear any dispute or difference submitted by a party to arbitration. If the parties fail to appoint a Sole Arbitrator by mutual consent, the same shall be appointed by the High Court of Chhattisgarh as per Section 11 of the Arbitration and Conciliation Act, 1996 on an application made by any of the parties for the purpose thereof.</li>
									<li>Arbitration proceedings shall be conducted in accordance with the Indian Arbitration and Conciliation Act, 1996 including any statutory modifications or re-enactment thereof and the rules made thereunder. The place of arbitration shall be Raipur, unless otherwise agreed between the parties. The language of arbitration shall be the language in which this contract is being executed.</li>
									<li>The decision of the arbitration tribunal shall be final and binding and shall be enforceable in any court of competent jurisdiction as a decree of the court. The parties thereby waive any objections to or claims of immunity from such enforcement.</li>
									<li>The arbitrator shall give a reasoned award.</li>
									<li>Notwithstanding any reference of dispute or difference under this clause, the parties shall continue to perform their respective obligations under the contract unless they otherwise agree.</li>
								</ol>
							</p>

							<h4 className="text-dark mt-5">LAW AND JURISDICTION</h4>
							<p className="mb-3">This Agreement or the dispute or claim arising out of or in connection with them or their subject matter or formation (including non-contractual disputes or claims) shall be governed by Indian Law. Any dispute or claim arising out of or in connection with such Agreement or their formation (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of the courts of Raipur, Chhattisgarh.</p>
						</div>
					</div>
				</div>
			</section>
		</div >
	);
};

export default Terms;
