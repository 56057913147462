import React, { useState, useEffect } from 'react'
import axios from 'axios';
import JobForm from './JobForm';
import JobDetails from './JobDetails';

const URL = require("../constants");

const JobView = (props) => {

    const id = parseInt(props.match.params.id)
    const { hide } = props

    const [data, setdata] = useState([])

    useEffect(() => {

        const GetData = async () => {

            await axios({
                method: 'get',
           //  url: `http://192.168.1.3/biz-skpl-job/api/v1/job-posts/view?id=${id}`,
             url: ` https://job.skcplindia.in/api/v1/job-posts/view?id=${id}`,
            }).then((res) => {
               // console.log(res);
                setdata(res.data.data)


            })
        }
      //  console.log(id)
        if(id){
            GetData()
        }
       
    }, [])


    return (
        <div className={!hide ? "mb-5" : 'container mt-3'}>
            <div className="row">
                {hide ? <>
                    <div className="col-lg-8">
                         <JobDetails data={data} />
                    </div>
                    <div className="col-lg-4 p-2">
                        <JobForm id={id} data={data} hide={hide} />
                    </div>
                   </> :
                   <>
                    <div className="col-lg-8">   
                       <JobForm id={id} data={data} hide={hide} />
                    </div>
                    <div className="col-lg-4 p-2">
                    <img alt="job" src="/images/about/01.png" width="100%" /> 
                    </div>
                   </>
                }
            </div>
            <div className="py-4"></div>
        </div>

    )
}

export default JobView
