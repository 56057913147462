import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useForm } from "react-hook-form";
import base64 from 'base-64';
import { COMPANYID } from '../constants';
import { JOB_TITLE } from '../constants';
import { useHistory } from 'react-router';

const JobForm = (props) => {


    const { id, data, hide } = props
    const history = useHistory()
    const [resumeFile, setResumeFile] = useState(null);

    const { register, handleSubmit, errors, reset, setValue } = useForm();
    useEffect(() => {
        setValue('job_title', !data ? '' : hide ? data.job_title : '')
    }, [data])

    const onSubmit = async (data) => {
        var ext = data.resume[0].name.substring(data.resume[0].name.lastIndexOf(".") + 1);

        const formData = new FormData();
       
        if(resumeFile){
            formData.append('files', resumeFile);
        }
        formData.append('job_id', `${id}`);
        formData.append('job_title', `${data.job_title}`);
        formData.append('mobile', `${data.mobile}`);
        formData.append('email', `${data.email}`);
        formData.append('name', `${data.name}`);
        formData.append('message', `${data.additional}`);
        formData.append('ext', `${ext}`);
        formData.append('company_id', `${COMPANYID}`); //1 for biz



        await axios({
            method: 'post',
            //   url: 'http://192.168.1.3/biz-skpl-job/api/v1/job-application/create',
            url: 'https://job.skcplindia.in/api/v1/job-application/create',
            // url: 'http://localhost/biz-skpl-job/api/v1/job-application/create',

            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then((data) => {
            alert(`Thank you for your message , We will updated you as soon as possible`);
            reset()
            if (hide) {
                window.location.href = '/career'
            }
        })

    }

 
    //console.log(data.job_title?.length)
    return (
        <div className="subscribe-inner">
            <form onSubmit={handleSubmit(onSubmit)}>
                <h5 className="text-center">{hide ? <> Apply For {data.job_title?.slice(0, 31)} {data.job_title?.length >= 31 ? "..." : ''}</> : <></>} </h5>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <label htmlFor="exampleFormControlInput1">Name</label>
                            <input ref={register({
                                required: "Name cannot be blank", pattern: {
                                    value: /^[a-zA-Z,\-\/\s]+$/,
                                    message: "No special characters are allowed"
                                }
                            })} name="name" type="text" className={errors.name ? "form-control is-invalid" : 'form-control'} id="exampleFormControlInput1" placeholder="Your Name" />
                            {errors.name && <small id="name" className="text-danger">{errors.name?.message}</small>}

                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <label htmlFor="exampleFormControlInput1">Email Address</label>
                            <input ref={register({
                                required: "Email cannot be blank",
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: "InValid Email Address"
                                },
                            })} name="email" type="email" className={errors.email ? "form-control is-invalid" : 'form-control'} id="exampleFormControlInput1" placeholder="Your Email Address" />
                            {errors.email && <small id="email" className="text-danger">{errors.email?.message}</small>}

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <label htmlFor="exampleFormControlInput1">Mobile no.</label>
                            <input type="tele" ref={register({
                                required: "Mobile No. cannot be blank",
                                pattern: {
                                    value: /^\d{10}$/,
                                },
                            })} className={errors.mobile ? "form-control is-invalid" : 'form-control'} name="mobile" id="exampleFormControlInput1" placeholder="Your Mobile No." />
                            {errors.mobile && <small id="mobile" className="text-danger">{errors.mobile?.message}</small>}
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <label htmlFor="exampleFormControlInput1">Job Title</label>
                            <input type="text" ref={register({ required: "Job title Cannot be blank" })} className={errors.job_title ? "form-control is-invalid" : 'form-control'} name="job_title" id="exampleFormControlInput1" disabled={hide} />
                            {errors.job_title && <small id="attachment" className="text-danger">{errors.job_title?.message}</small>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea1">Additional Information</label>
                            <textarea className={errors.message ? "form-control is-invalid" : 'form-control'} name="additional" ref={register({ required: "Required" })} id="exampleFormControlTextarea1" rows="3" placeholder="Additional Information"></textarea>
                            {errors.message && <small id="additional" className="text-danger">{errors.message?.message}</small>}
                        </div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                        <div className="form-group">
                            <label htmlFor="exampleFormControlInput1">Attachment</label>
                            <input type="file"
                            onChange={(event) => {
                                const file = event.target.files[0];
                                
                                setResumeFile(file);
                              }}
                             ref={register({
                                required: "Attachment cannot be blank",
                                pattern: {
                                    value: /(\.pdf|\.docx|doc)$/i,
                                    message: "InValid File Format"
                                },
                            })} name="resume" className={errors.resume ? "form-control is-invalid" : 'form-control'} id="exampleFormControlInput1" />
                            {errors.resume?.message && <small id="attachment" className="text-danger">{errors.resume?.message}</small>}
                        </div>
                    </div>
                </div>
                <button className="btn btn-theme btn-circle sm">Send Message </button>
            </form>
        </div>
    )
}

export default JobForm
