import React from "react";
import privacyData from "../../data.json";

const Privacy = () => {
	return (
		<div className="page-content">
			<section>
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12">
							<h2 className="title mb-0">
								{privacyData.data.policy.privacy.title}
							</h2>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col-lg-12 col-md-12">
							{privacyData.data.policy.privacy.description.map(
								(desc, index) => (
									<p key={index}>{desc}</p>
								)
							)}
							
							{privacyData.data.policy.privacy.info_usage.map(
								(pinfo, index) => (
									<div key={index}>
										<h4 className="mt-5 text-dark">
										<b>{`${pinfo["title"]}`}</b>
										</h4>
										<p>
										{`${pinfo["description"]}`}
										</p>
									</div>
								)
							)}
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Privacy;
