import React from "react";
import dataFaq from "../../data.json";

const Questions = () => {
	let counter = 1;
	return (
		<>
			<section className="z-index-1">
				<div className="container">
					<div className="row text-center">
						<div className="col-lg-8 col-md-12 ml-auto mr-auto">
							<div className="section-title">
								<div className="title-effect title-effect-2">
									<div className="ellipse"></div>{" "}
									<i className="la la-cubes"></i>
								</div>
								<h2 className="title">
									{dataFaq.data.faq.sub_title}
								</h2>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12 ml-auto">
							<div id="accordion" className="accordion style-1">
								<div
									className='card active'>
									<div className="card-header">
										<h6 className="mb-0">
											<a
												data-toggle="collapse"
												data-parent="#accordion"
												href='#collapse1'
												aria-expanded="true"
											>What is an ERP system?</a>
										</h6>
									</div>
									<div
										id='collapse1'
										className='collapse show' data-parent="#accordion"
									>
										<div className="card-body">
											<p>An Enterprise Resource Planning system is an integrated software planning solution used to manage a company’s business processes and resources. You can install them with the help of the best software company in Raipur, which is Bizolution. We provide many business management functions like accounting, planning, project management, processing, manufacturing, human resources, and other varied business processes.
											</p>
										</div>
									</div>
								</div>
								<div
									className='card'>
									<div className="card-header">
										<h6 className="mb-0">
											<a
												data-toggle="collapse"
												data-parent="#accordion"
												href='#collapse2'
												aria-expanded="false"
											>What are the main benefits of an ERP System?</a>
										</h6>
									</div>
									<div
										id='collapse2'
										className='collapse' data-parent="#accordion"
									>
										<div className="card-body">
											<p>An ERP system is a set of business solutions to help your business gain its traction. It
												has various benefits such as:</p>
											<ul class="custom-li list-unstyled list-icon-2 d-inline-block">
												<li>Streamlines workflow processes</li>
												<li>Data accuracy</li>
												<li>Operations processing</li>
												<li>Business transparency</li>
												<li>Improved customer services</li>
												<li>Fast business growth!</li>
											</ul>
											<p>Get your ERP software solutions from the best ERP software company in Raipur, Bizolution! Scale your business growth, we are here to deliver.</p>
										</div>
									</div>
								</div>
								<div
									className='card'>
									<div className="card-header">
										<h6 className="mb-0">
											<a
												data-toggle="collapse"
												data-parent="#accordion"
												href='#collapse3'
												aria-expanded="false"
											>How do I choose the right ERP system?</a>
										</h6>
									</div>
									<div
										id='collapse3'
										className='collapse' data-parent="#accordion"
									>
										<div className="card-body">
											<p>Depending on your business modules, dynamics, and functions, you can choose an ERP
												software solution that is best suited for your growth, especially from a software company in Raipur!</p>
											<p>From cost benefit analysis, accurate implementation, employee feedback on functions,
												selection of ERP vendor, and long term business planning, you can get yourself
												customized ERP solutions! The only ERP software company in Raipur that can help you
												with customized ERP solutions is Bizolution!</p>
										</div>
									</div>
								</div>
								<div
									className='card'>
									<div className="card-header">
										<h6 className="mb-0">
											<a
												data-toggle="collapse"
												data-parent="#accordion"
												href='#collapse4'
												aria-expanded="false"
											>Why does my business need a customized ERP solutions system?</a>
										</h6>
									</div>
									<div
										id='collapse4'
										className='collapse' data-parent="#accordion"
									>
										<div className="card-body">
											Business customization and automation is a need of the hour in this digital age. All your
											business solutions and growth mechanics are dependent on how compatible ERP
											systems are for your business! For continuous business growth and faster paced
											solutions system, your business will always require customized business and ERP
											solutions systems, which you can get from Bizolution, the best ERP software company
											in Raipur.
										</div>
									</div>
								</div>
								<div
									className='card'>
									<div className="card-header">
										<h6 className="mb-0">
											<a
												data-toggle="collapse"
												data-parent="#accordion"
												href='#collapse5'
												aria-expanded="false"
											>What are the advantages of customized business solutions?</a>
										</h6>
									</div>
									<div
										id='collapse5'
										className='collapse' data-parent="#accordion"
									>
										<div className="card-body">
											<p>An ERP software company in Raipur like Bizolution can help your company receive many advantages of customized business solutions. Here are some of the main advantages from which your business will benefit:</p>
											<ul class="custom-li list-unstyled list-icon-2 d-inline-block">
												<li>Designed for your unique business requirements</li>
												<li>Advanced analytics</li>
												<li>Accurate budget solutions</li>
												<li>Customizable and user-friendly</li>
												<li>Automated customer servicing</li>
												<li>Budget friendly</li>
											</ul>
											<p>Get these favourable advantages for your business growth today with the best ERP
												software company in Raipur!</p>
										</div>
									</div>
								</div>
								<div
									className='card'>
									<div className="card-header">
										<h6 className="mb-0">
											<a
												data-toggle="collapse"
												data-parent="#accordion"
												href='#collapse6'
												aria-expanded="false"
											>What are the signs that my business definitely needs an ERP system?</a>
										</h6>
									</div>
									<div
										id='collapse6'
										className='collapse' data-parent="#accordion"
									>
										<div className="card-body">
											<p>Any business, small, medium, or large, can benefit from ERP systems. Especially, if your
												business requires major help in streamlining its processes. Bizolution, an ERP software
												company in Raipur, can help your business eliminate all problems. The problems can be
												varied from burdening manual processes, constant mis-management, using multiple
												software systems, increasing supply chain issues, and miscellaneous expenses. If your
												business is going through these difficulties, get your ERP software solutions from the
												best ERP software company in Raipur, Bizolution.</p>

										</div>
									</div>
								</div>
								<div
									className='card'>
									<div className="card-header">
										<h6 className="mb-0">
											<a
												data-toggle="collapse"
												data-parent="#accordion"
												href='#collapse7'
												aria-expanded="false"
											>Why do businesses need automation?</a>
										</h6>
									</div>
									<div
										id='collapse7'
										className='collapse' data-parent="#accordion"
									>
										<div className="card-body">
											<p>Automation is one of the most important requirements for businesses because all businesses need standardized systems that they can get from Bizolution, a software
												company in Raipur. Automation provides reduced turn-around time, decreases costs, is more reliable than manual labour, has an efficient operations system, provides stability and is consistent with processing quality. Therefore, get your business’ automation done with the best ERP software company in Raipur, Bizolution.</p>
										</div>
									</div>
								</div>
								<div
									className='card'>
									<div className="card-header">
										<h6 className="mb-0">
											<a
												data-toggle="collapse"
												data-parent="#accordion"
												href='#collapse8'
												aria-expanded="false"
											>What does a growing start-up need to follow?</a>
										</h6>
									</div>
									<div
										id='collapse8'
										className='collapse' data-parent="#accordion"
									>
										<div className="card-body">
											<p>A growing start-up should always follow the minimum viable product model, have a visionary and in-sync team, build a structure fully on automation, focus on work quality instead of profits, and be consistent with their results. If you want all these factors to help your start-up grow, you need an automated system to handle them. Contact Bizolution, the best ERP software company in Raipur, for your start-up’s seamless
												automation!</p>
										</div>
									</div>
								</div>
								<div
									className='card'>
									<div className="card-header">
										<h6 className="mb-0">
											<a
												data-toggle="collapse"
												data-parent="#accordion"
												href='#collapse9'
												aria-expanded="false"
											>How long does it take to implement ERP systems?</a>
										</h6>
									</div>
									<div
										id='collapse9'
										className='collapse' data-parent="#accordion"
									>
										<div className="card-body">
											<p>When you have narrowed down on your selection and priority of ERP systems, the implementation process is very fast and guarantees streamlined processes almost immediately. If you have chosen the best ERP software company in Raipur, such as Bizolution, the implementation would be way smoother than you would expect.</p>
										</div>
									</div>
								</div>
								<div
									className='card'>
									<div className="card-header">
										<h6 className="mb-0">
											<a
												data-toggle="collapse"
												data-parent="#accordion"
												href='#collapse10'
												aria-expanded="false"
											>How should the business employees be familiar with ERP systems?</a>
										</h6>
									</div>
									<div
										id='collapse10'
										className='collapse' data-parent="#accordion"
									>
										<div className="card-body">
											<p>During implementation, the ERP system’s training is always given to the employees by
												the most efficient software company in Raipur. A mandatory step in implementation
												methodology can help the employees get educated on how to use the ERP system
												effectively for the different business objectives. Get the best ERP software company in
												Raipur, Bizolution, to get your employees familiar and efficient with the automated and
												customized ERP systems!</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};
export default Questions;
