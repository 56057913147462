import React from "react";
import dataHerosection from "../../../data.json";
const URL = require("../../../constants");

const HeroSectionTwo = () => {
	return (
		<>
			{/* hero section :: start */}
			<section className="fullscreen-banner banner p-0 custom-banner o-hidden">
			<div className="hero-bg"><img className="img-fluid" src="images/bg/04.png" alt="" /></div>
			<div className="bg-animation"><img src="images/pattern/01.png" alt="" /></div>
				<div className="h-100">
					<div className="align-center pt-0">
						<div className="container">
							<div className="row align-items-center">
								<div className="col-lg-12 col-md-12 order-lg-12">
									<div
										className="owl-carousel owl-theme no-pb"
										data-dots="false"
										data-items="1"
										data-autoplay="true"
									>
										<div className="item">
											<div className="row align-items-center">
												<div className="col-lg-7 col-md-12 md-mt-5 order-lg-1">
													<div className="">
														<img
															className="img-center"
															src="images/banner/b01.png"
															alt="ERP Software Company In Raipur"
														/>
													</div>
												</div>
												<div className="col-lg-5 col-md-12 order-lg-12">
													<h1
														className="mb-4 wow fadeInUp"
														data-wow-duration="1.5s"
													>
														ERP Software Company In Raipur
													</h1>
													<a
												className="btn btn-theme"
												href="/contact-us" title="Know more"
											>Know more</a> 
												</div>
											</div>
										</div>
										<div className="item">
											<div className="row align-items-center">
												<div className="col-lg-7 col-md-12 md-mt-5 order-lg-1">
													<div className="">
														<img
															className="img-center"
															src="images/banner/b02.png"
															alt="We can help you pump life into your amazing business idea!"
														/>
													</div>
												</div>
												<div className="col-lg-5 col-md-12 order-lg-12">
													<h2
														className="mb-4 wow fadeInUp"
														data-wow-duration="1.5s"
													>
														We can help you pump life into your amazing business idea!
													</h2>
													<p
														className="lead mb-5 wow fadeInUp"
														data-wow-duration="2s"
														data-wow-delay="0.3s"
													>Bizolution is a software company in Raipur built to help your business
													thrive!</p>
													<a
												className="btn btn-theme"
												href="/contact-us" title="Know more"
											>Know more</a>
												</div>
											</div>
										</div>
										<div className="item">
											<div className="row align-items-center">
												<div className="col-lg-7 col-md-12 md-mt-5 order-lg-1">
													<div className="">
														<img
															className="img-center"
															src="images/banner/b03.png"
															alt="Let's build your unique enterprise together"
														/>
													</div>
												</div>
												<div className="col-lg-5 col-md-12 order-lg-12">
													<h2
														className="mb-4 wow fadeInUp"
														data-wow-duration="1.5s"
													>Let's build your unique enterprise together</h2>
													<p
														className="lead mb-5 wow fadeInUp"
														data-wow-duration="2s"
														data-wow-delay="0.3s"
													>Get an ERP software company in Raipur like Bizolution, and change your game!
													</p>
													<a
												className="btn btn-theme"
												href="/contact-us" title="Know more"
											>Know more</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default HeroSectionTwo;
