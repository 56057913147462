import React from "react";
import Header from "../../Header1";
import Footer from "../../Footer1";
import Title from "./Title";
import Form from "./Form";

const ContactOne = () => {
	return (
		<>
			<Header />
			<Title />
			<Form />
			<Footer />
		</>
	);
};
export default ContactOne;
