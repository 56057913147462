import React from 'react'
import Header1 from '../../Header1'
import Footer from "../../Footer1";
import Title from '../../careers/Title'
import JobView from '../../careers/JobView'
        const JobForm = (props) => {
return (
        <>
<Header1/>
<Title/>
<div className="page-content">
    <JobView {...props} hide={true}/>
</div>
<Footer />
</>
    )
}

export default JobForm
