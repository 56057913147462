import React, { useState } from 'react';
import dataContact from "../../data.json";
import { useForm } from "react-hook-form";
import axios from "axios";

const Form = () => {
	const { register, handleSubmit, errors } = useForm();
	const [status, setSuccess] = useState(false);
	const onSubmit = async (data) => {

		document.getElementById("contact-form").reset();
		const formData = new FormData();
		formData.set('firstName', `${data.firstName}`);
		formData.set('lastName', `${data.lastName}`);
		formData.set('email', `${data.email}`);
		formData.set('phone', `${data.phone}`);
		formData.set('message', `${data.message}`);
		await axios({
			method: 'post',
			url: 'http://api.skcplindia.in/api-bizolutiontech/',
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' }
		}).then((data) => {
			console.log(data);
			
		})
		setSuccess(true);
		
		// alert(JSON.stringify(data));
	};

	return (
		<div className="page-content">
			<section className="contact-1">
				<div className="container">
					{status &&
						<div className="alert alert-success" role="alert">
							Message submitted successfully!
						</div>
					}
					<div className="row">
						<div className="col-xl-6 col-lg-8 col-md-12 mr-auto">
							<div className="section-title">
								<div className="title-effect">
									<div className="bar bar-top"></div>
									<div className="bar bar-right"></div>
									<div className="bar bar-bottom"></div>
									<div className="bar bar-left"></div>
								</div>
								<h6>
									{dataContact.data.contact[0].details.title}
								</h6>
								<h2>
									{
										dataContact.data.contact[0].details
											.sub_title
									}
								</h2>
								<p>
									{
										dataContact.data.contact[0].details
											.description
									}
								</p>
							</div>
						</div>
					</div>
					<div className="row align-items-center">
						<div className="col-md-7">

							<form
								id="contact-form"
								onSubmit={handleSubmit(onSubmit)}
							>
								<div className="messages"></div>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<label htmlFor="firstName">
												First Name
											</label>
											{errors.firstName && (
												<span className="help-block with-errors">
													Firstname is required.
												</span>
											)}

											<input
												id="form_name"
												type="text"
												name="firstName"
												className="form-control"
												placeholder="Type First name"
												ref={register({
													required: "Required",
												})}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<label htmlFor="lastName">
												Last Name
											</label>
											{errors.lastName && (
												<span className="help-block with-errors">
													Lastname is required.
												</span>
											)}
											<input
												id="form_lastname"
												type="text"
												name="lastName"
												className="form-control"
												placeholder="Type Last name"
												ref={register({
													required: "Required",
												})}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											<label htmlFor="email">
												Email Address
											</label>
											{errors.email && (
												<span className="help-block with-errors">
													Valid email is required.
												</span>
											)}
											<input
												id="form_email"
												type="email"
												name="email"
												className="form-control"
												placeholder="Type Email"
												ref={register({
													required: "Required",
													pattern: {
														value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
													},
												})}
											/>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group">
											<label>Phone Number</label>
											{errors.phone && (
												<span className="help-block with-errors">
													Valid phone required (10
													digits)
												</span>
											)}

											<input
												id="form_phone"
												type="tel"
												name="phone"
												className="form-control"
												placeholder="Type Phone"
												ref={register({
													required: "Required",
													pattern: {
														value: /^\d{10}$/,
													},
												})}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12">
										<div className="form-group">
											<label>Message</label>
											{errors.message && (
												<span className="help-block with-errors">
													Please,leave us a message.
												</span>
											)}
											<textarea
												id="form_message"
												name="message"
												className="form-control"
												placeholder="Type Message"
												rows="4"
												ref={register({
													required: "Required",
												})}
											></textarea>
										</div>
									</div>
									<div className="col-md-12 mt-2">
										<button
											className="btn btn-theme btn-circle"
											data-text="Send Message"
											type="submit"
										>
											<span>S</span>
											<span>e</span>
											<span>n</span>
											<span>d</span>
											<span> </span>
											<span>M</span>
											<span>e</span>
											<span>s</span>
											<span>s</span>
											<span>a</span>
											<span>g</span>
											<span>e</span>
										</button>
									</div>
								</div>
							</form>
						</div>
						<div className="col-md-5">
							<img
								className="img-center"
								src="images/contact.png"
								alt="Contact Us"
							/>
						</div>
					</div>
				</div>
			</section>

			<section className="contact-info p-0 z-index-1">
				<div className="container">
					<div className="row align-items-center">
						<div className="col-lg-4 col-md-12">
							<div className="contact-media">
								{" "}
								<i className="flaticon-paper-plane"></i>
								<span>Address:</span>
								<p>
									{
										dataContact.data.contact[0].details
											.address
									}
								</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 md-mt-5">
							<div className="contact-media">
								{" "}
								<i className="flaticon-email"></i>
								<span>Email Address</span>
								<p>
									<a
										href={`mailto:${dataContact.data.contact[0].details.email}`} title={dataContact.data.contact[0].details.email}
									>
										{" "}
										{dataContact.data.contact[0].details.email}
									</a><br />
									<a
										href={`mailto:${dataContact.data.contact[0].details.emailone}`} title={dataContact.data.contact[0].details.emailone}
									>
										{" "}
										{dataContact.data.contact[0].details.emailone}
									</a></p>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 md-mt-5">
							<div className="contact-media">
								{" "}
								<i className="flaticon-social-media"></i>
								<span>Phone Number</span>
								<p><a
									href={`mailto:${dataContact.data.contact[0].details.phone}`} title={dataContact.data.contact[0].details.phone}
								>
									{dataContact.data.contact[0].details.phone}
								</a></p>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="o-hidden p-0 custom-mt-10 z-index-0">
				<div className="container-fluid p-0 mb-5">
					<div className="row align-items-center">
						<div className="col-md-12">
							<div className="map iframe-h-2">
								<iframe
									title="GoogleMap"
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.6191325367136!2d81.65689591533439!3d21.246946485668754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a28ddae1c713a07%3A0x992e2d8eb94bb843!2sBizolution%20Technologies%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1623304986344!5m2!1sen!2sin"
									allowFullScreen=""
								></iframe>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Form;
