import React from "react";
import dataTechtool from "../../../data.json";
const URL = require("../../../constants");

const Techtool = () => {
	return (
		<section className="pos-r text-center">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-xl-12 col-lg-12 col-md-12">
						<div className="section-title mb-3">
							<div className="title-effect title-effect-2">
								<div className="ellipse"></div>{" "}
								<i className="la la-users"></i>
							</div>
							<h2 className="title">
								{dataTechtool.data.home[1].content.techtool.title}
							</h2>
						</div>
					</div>
				</div>
				<div className="row align-items-center">
					<div className="col-lg-12 col-md-12 ml-auto xs-mt-5">
						<div className="row">
							{dataTechtool.data.home[1].content.techtool.details.map(
								(techtool, index) => (
									<div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 mt-3" key={index}>
										<div className="featured-item custom-tech-item">
											<div className="featured-icon">
												<img
													className="img-center"
													src={`${URL.img_url}${techtool["img_url"]}`}
													alt={techtool["title"]}
												/><h5 className="mt-2">{techtool["title"]}</h5>
											</div>
										</div>
									</div>
								)
							)}
						</div>
						<div className="row">
							<div className="col-lg-3"></div>
							{dataTechtool.data.home[1].content.techtool.detailsTwo.map(
								(techtool, index) => (
									<div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 mt-3" key={index}>
										<div className="featured-item custom-tech-item">
											<div className="featured-icon">
												<img
													className="img-center"
													src={`${URL.img_url}${techtool["img_url"]}`}
													alt={techtool["title"]}
												/><h5 className="mt-2">{techtool["title"]}</h5>
											</div>
										</div>
									</div>
								)
							)}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
export default Techtool;
