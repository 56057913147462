import React from "react";
import dataFeatures from "../../data.json";
const URL = require("../../constants");

const Features = () => {
	return (
		<>
			<section className="pos-r o-hidden text-center">
				<div className="bg-animation">
					<img
						className="zoom-fade"
						src="images/pattern/03.png"
						alt={dataFeatures.data.about[1].features.title}
					/>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-lg-8 col-md-12 ml-auto mr-auto">
							<div className="section-title">
								<div className="title-effect title-effect-2">
									<div className="ellipse"></div>{" "}
									<i className="la la-cubes"></i>
								</div>
								<h2 className="title">
									{
                                        dataFeatures.data.product[1].Productfeatures.title
										// dataFeatures.data.product[1].Productfeatures.details[0].title
									}
								</h2>
								<h5 className="title">
									{
										  dataFeatures.data.product[1].Productfeatures.sub_title
                                    }
								</h5>
								{/* <p>
									{
										dataFeatures.data.about[1].features.description
									}
								</p> */}
							</div>
						</div>
					</div>
				</div>
				{dataFeatures.data.product[1].Productfeatures.details.map(
					(feature, index) => (
						<section className={`pos-r pt-0 count-feature`} key={index}>
							<div className="container " >
								<div className={`row ${index % 2 === 1 ? "flex-row-reverse" : ""} align-items-center`}>
									<div className="col-lg-5 col-md-12">
										<img
											// className="img-fluid w-100 "
											style={{maxWidth:"90%", height:"auto"}}
											src={`${URL.img_url}${feature["icon"]}`}
											alt={feature["title"]}
										/>
									</div>
									<div className="col-lg-7 col-md-12 md-mt-5">
										<div className="section-title mb-4">
											<h2>
												{feature.title}
											</h2>
										</div>
										{/* <ul className="custom-li list-unstyled list-icon-2 d-inline-block"> */}

										<ul className="list-unstyled list-icon text-left feature-custom-item">
											{feature["itemlists"].map(
												(item, index) => (
													<li className="fs-2" key={index}>{" "}
														{item} 
														
													</li>
												)
											)}
										</ul>
									</div>
								</div>
							</div>
						</section>
					)
				)}
			</section>
			{/* <div class="btn-sm col d-flex justify-content-center" role="group" aria-label="Basic example">


  <button type="button" class="btn btn-primary">Know More About Entera</button>
  <button type="button" class="btn btn-primary">Entera Price Range</button>
  
</div> */}
<div class="btn-sm col d-flex justify-content-center" role="group" aria-label="Basic example">
  
  <a href="https://entera.in/#pricing" class="btn btn-primary">Entera Price Range</a>
  <a href="https://entera.in/" class="btn btn-primary">Know More About Entera</a>
</div>

		</>
	);
};
export default Features;
