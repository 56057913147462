import React from "react";
import privacyData from "../data.json";;

const Refund = () => {
	return (
		<div className="page-content">
			<section>
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12">
							<h2 className="title mb-0">
								{/* {privacyData.data.policy.privacy.title} */}
                                Refund Policy
							</h2>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col-lg-12 col-md-12">
							{/* {privacyData.data.policy.privacy.description.map(
								(desc, index) => (
									<p key={index}>{desc}</p>
								)
							)} */}
							
							{/* {privacyData.data.policy.privacy.info_usage.map(
								(pinfo, index) => (
									<div key={index}>
										<h4 className="mt-5 text-dark">
										<b>{`${pinfo["title"]}`}</b>
										</h4>
										<p>
										{`${pinfo["description"]}`}
										</p>
									</div>
								)
							)} */}
                            <p>
                            At Bizolution Technologies, we strive to provide exceptional services to our valued customers. This Refund Policy outlines our guidelines regarding refunds for our services.

Please note that Bizolution Technologies does not offer refunds if a customer no longer requires our services. Once a customer has subscribed to our service and made the necessary payment, we consider it as an agreement between the customer and our company.

However, it is important to acknowledge that our refund policy is subject to revision by the company at any time. We reserve the right to amend this policy to ensure it aligns with our business requirements and industry standards.

We highly recommend all customers to carefully assess their needs and evaluate our services before making a purchase. If you have any concerns or questions regarding our services, please reach out to our customer support team. We are here to assist you and provide further clarification on our offerings.
                            </p>
						</div>
					</div>
				</div>
                <div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12">
							<h2 className="title mb-0">
								{/* {privacyData.data.policy.privacy.title} */}
                                Cancellation Policy
							</h2>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col-lg-12 col-md-12">
							{/* {privacyData.data.policy.privacy.description.map(
								(desc, index) => (
									<p key={index}>{desc}</p>
								)
							)} */}
							
							{/* {privacyData.data.policy.privacy.info_usage.map(
								(pinfo, index) => (
									<div key={index}>
										<h4 className="mt-5 text-dark">
										<b>{`${pinfo["title"]}`}</b>
										</h4>
										<p>
										{`${pinfo["description"]}`}
										</p>
									</div>
								)
							)} */}
                            <p>
                            At Bizolution Technologies, we understand that circumstances may change, and sometimes our customers may need to cancel their subscription with us. We have developed a subscription cancellation policy to ensure a transparent and smooth process for our valued clients.
To initiate a cancellation, customers are required to submit a cancellation request through our designated communication channels, such as email or our customer support portal. We kindly request that cancellation requests be made at least 30 days prior to the next billing cycle to allow us sufficient time to process the request.
Upon receipt of a cancellation request, our dedicated team will review and verify the details provided. We may reach out to the customer to gather additional information or clarify any concerns. Once the cancellation request is confirmed, we will send a notification to the customer acknowledging the cancellation.
Please note that any outstanding charges or dues up until the cancellation date must be settled. Customers are responsible for paying any applicable fees, charges, or penalties associated with the cancellation, as outlined in the terms and conditions agreed upon during the subscription signup process.

It is important to mention that certain services or products may have specific cancellation policies or restrictions. These will be clearly communicated to the customer at the time of subscription or in the terms and conditions provided. We encourage our customers to review the applicable cancellation policies for each specific service or product.
                            </p>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Refund;
