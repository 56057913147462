import React from "react";
import { useForm } from "react-hook-form";
//import ColorCustomizer from "./ColorCustomizer";
import ScrollTop from "./ScrollTop";

const Footer1 = ({showFooter=true}) => {
	const { register, handleSubmit } = useForm();
	const onSubmit = (data) => {
		alert(JSON.stringify(data));
	};
	console.log(
		"asjdhahfh ", showFooter
	)
	return (
		<>
			{/* footer start */}
			<footer
				className="footer footer-1 pos-r"
				data-bg-img="images/bg/05.png"
			>

				{
					showFooter ? (
						<div className="subscribe-box">
					<div className="container">
						<div className="row subscribe-inner align-items-center text-center">
							<div className="col-md-12 col-sm-12 ml-auto mr-auto">
								<h4 className="wow slideInLeft">Missed your sales forecast ? Or a key deadline?</h4>
								<p className="lead mb-3 wow zoomIn">
									Or just looking to digitise your processes to drive growth?
								</p>
								<a
									className="btn btn-theme wow fadeInUp"
									data-wow-duration="1s"
									data-wow-delay="0.5s"
									href="/refund" title="Start by hiring us!"
								>
									Start by hiring us!</a>
							</div>
						</div>
					</div>
				</div>
					) : null
				}
				
				<div className="primary-footer">
					<div className="container">
						<div className="row">
							<div className="col-lg-4 col-md-6">
								<div className="footer-logo">
									<img
										id="footer-logo-img"
										src="/images/logo.png"
										className="img-center"
										alt="Bizolution Technologoes Pvt. Ltd."
									/>
								</div>
								<p className="mb-0">
									Bizolution is the new face of tech solutions in corporate world, as advanced services are the answers to a successful enterprise, whether new or old.
								</p>
							</div>
							<div className="col-lg-4 col-md-6 pl-md-5 sm-mt-5 footer-list justify-content-between d-flex">
								<ul className="list-unstyled w-100">
									<li>
										<a href="/about-us" title="About Us">About Us</a>
									</li>
									<li>
										<a href="/product" title="Service">Product</a>
									</li>
									<li>
										<a href="/contact-us" title="Contact Us">Contact Us</a>
									</li>
									<li>
										<a href="/refund" title="Contact Us">Refund & Cancellation</a>
									</li>
								</ul>
								<ul className="list-unstyled w-100">
									<li>
										<a href="/blog/5-tech-mistakes-made-by-start-ups" title="Blog">Blog</a>
									</li>
									<li>
										<a href="/faq" title="Faq">Faq</a>
									</li>
									<li>
										<a href="/privacy" title="Privacy Policy">Privacy Policy</a>
									</li>
									<li>
										<a href="/terms" title="Terms">Terms & Conditions</a>
									</li>
								</ul>
							</div>
							<div className="col-lg-4 col-md-12 md-mt-5">
								<ul className="media-icon list-unstyled">
									<li>
										<p className="mb-0">
											Address:{" "}
											<b>
												4/12, Second Floor, Shree Tower, New Shanti Nagar, Raipur, Chhattisgarh 492007
											</b>
										</p>
									</li>
									<li>
										Email:{" "}
										<a href="mailto:info@bizolutiontech.com" title="info@bizolutiontech.com">
											<b>info@bizolutiontech.com</b>
										</a><br />
										&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href="mailto:sales@bizolutiontech.com" title="sales@bizolutiontech.com">
											<b>sales@bizolutiontech.com</b>
										</a>
									</li>
									<li>
										Phone:{" "}
										<a href="tel:+918889501682" title="+91-888-950-1682">
											<b>+91-888-950-1682</b>
										</a>
									</li>
									<li>
										WhatsApp:{" "}
										<a href="tel:+918889501682" title="+91-888-950-1682">
											<b>+91-888-950-1682</b>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="secondary-footer">
					<div className="container">
						<div className="copyright">
							<div className="row align-items-center">
								<div className="col-lg-6 col-md-12">
									{" "}
									<span>
										Copyright 2021 BizolutionTech {" "} | All Rights Reserved
									</span>
								</div>
								<div className="col-lg-6 col-md-12 text-lg-right md-mt-3">
									<div className="footer-social">
										<ul className="list-inline">
											<li className="mr-2">
												<a href="https://www.linkedin.com/company/bizolution-technologies/" target="_blank" title="LinkedIn" rel="noreferrer">
													<i className="fab fa-linkedin"></i>{" "}
													LinkedIn
												</a>
											</li>
											<li className="mr-2">
												<a href="https://www.instagram.com/bizolutiontech/" target="_blank" title="Instagram" rel="noreferrer">
													<i className="fab fa-instagram"></i>{" "}
													Instagram
												</a>
											</li>
											<li>
												<a href="https://www.facebook.com/bizolutiontech" target="_blank" title="Facebook" rel="noreferrer">
													<i className="fab fa-facebook"></i>{" "}
													Facebook
												</a>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
			{/* footer end */}
			{/* <ColorCustomizer /> */}
			<ScrollTop />
		</>
	);
};

export default Footer1;