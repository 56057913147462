import React from "react";
import Features from "./Features";
import About from "./About";
import Steps from "./Steps";
import Team from "./Team";
import Faq from "./Questions";
import Testimonial from "./Testimonial";
import Industry from "./Industry";
import Startup from "./startup";
import Techtool from "./Techtool";
import Blogs from "./Blogs";
import HeroSection from "./HeroSection";

const PageContent = () => {
	return (
		<>
			<HeroSection />
			<div className="page-content">
				<About />
				<Features />
				<Startup />
				<Industry />
				<Steps />
				<Techtool />
				<Testimonial />
				<Blogs />
				<Faq />
			</div>
		</>
	);
};
export default PageContent;
